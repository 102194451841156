define("ui-nela/templates/components/ui-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bNxRek2T",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[23,0,[\"btnIcon\"]],\" \",[28,\"if\",[[23,0,[\"btnText\"]],\"pr-1\"],null]]]],[8],[9],[0,\"\\n  \"],[1,[23,0,[\"btnText\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-button.hbs"
    }
  });
  _exports.default = _default;
});