define("ui-nela/templates/components/ui-crud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "oHKDMyHJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui-crud__body\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row h-100\"],[8],[0,\"\\n    \"],[14,1,[[28,\"hash\",null,[[\"list\",\"view\"],[[28,\"component\",[\"ui-crud/list\"],null],[28,\"component\",[\"ui-crud/view\"],null]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-crud.hbs"
    }
  });
  _exports.default = _default;
});