define("ui-nela/templates/components/ui-form/group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bgDrVIpP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"label\",\"status\"],[[28,\"component\",[\"ui-form/group/label\"],null],[28,\"component\",[\"ui-form/group/status\"],[[\"message\"],[[23,0,[\"validationMessage\"]]]]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-form/group.hbs"
    }
  });
  _exports.default = _default;
});