define("nela-intro/services/intro", ["exports", "introjs", "ember-concurrency", "ember-require-module"], function (_exports, _introjs, _emberConcurrency, _emberRequireModule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    toast: Ember.inject.service(),
    router: Ember.inject.service(),
    accessedIntro: false,
    init() {
      this._super(...arguments);
      this.disableLinksFn = Ember.run.bind(this, ev => {
        ev.stopPropagation();
        ev.preventDefault();
        this.toast.info('Não é possível interagir com botões e links durante o tutorial.');
        return;
      });
    },
    // Inicia após verificar se a intro ainda não foi visualizada.
    start(permissions) {
      this._docReady(() => {
        this._validateAccess.perform(permissions);
      });
    },
    // Inicia imediatamente sem verificar.
    startNow(permissions, type) {
      this._docReady(() => {
        let intro = type ? this.create(permissions, type) : this.create(permissions);
        if (intro) {
          intro.start();
        }
      });
    },
    // Retorna o objeto Intro com as opções da página para o uso manual.
    create(permissions, type) {
      try {
        let routeName = this.router.currentRoute.name;
        let pageOptions = type ? this._getPageOptions(type) : this._getPageOptions();
        pageOptions.steps = this._disableSteps(pageOptions.steps, permissions);
        this._disableClicks(pageOptions.steps);
        let intro = (0, _introjs.default)();
        intro.setOptions(pageOptions).setOptions({
          showProgress: true,
          nextLabel: 'Próximo',
          prevLabel: 'Anterior',
          skipLabel: 'Fechar',
          doneLabel: 'Concluir'
        }).onafterchange(targetElement => {
          // Impede que um elemento seja exibido sobre o Navbar
          let rect = targetElement.getBoundingClientRect();
          if (rect.top < 70) {
            window.scroll(0, 0);
          }

          // Pula as etapas cujo o elemento alvo não está renderizado no DOM.
          if (!type) {
            if (targetElement.classList.contains('introjsFloatingElement') && intro._currentStep !== 0) {
              if (intro._direction === 'forward') {
                intro.nextStep();
              } else {
                intro.previousStep();
              }
            }
          }

          // Faz com que a primeira etapa tenha uma posição fixa.
          let isFirstStep = intro._currentStep === 0;
          let introjsTooltip = document.querySelector('.introjs-tooltipReferenceLayer');
          if (introjsTooltip) {
            introjsTooltip.style.position = 'fixed';
          }
        }).onexit(() => {
          // Após fechar o tour, adiciona um efeito ao botão de inicialização para chamar a atenção do usuário.
          Ember.set(this, 'showAlertButton', true);

          // Após fechar o tour, habilita os clicks novamente.
          this._enableClicks(pageOptions.steps);
          Ember.run.later(() => {
            Ember.set(this, 'showAlertButton', false);
          }, 1500);
          if (routeName === 'feed.index.index') {
            Ember.set(this, 'accessedIntro', true);
          }
        });
        if (type) {
          intro.setOptions({
            tooltipClass: 'goalsHelp'
          });
        }
        return intro;
      } catch (e) {
        Ember.debug(e);
      }
    },
    // Verifica no servidor se a página já foi acessada.
    _validateAccess: (0, _emberConcurrency.task)(function* (permissions) {
      try {
        let ENV = Ember.getOwner(this).factoryFor('config:environment').class;
        let generalOptions = ENV['nela-intro'] || {};
        let socialApi = ENV.apiUrl.social;
        let routeName = this.router.currentRoute.name;
        let moduleName = generalOptions.moduleName;
        if (!moduleName) {
          throw 'É necessário informar o nome do módulo no arquivo "config/environment".';
        }
        let {
          alreadyAccessed
        } = yield this.ajax.request(`showIntroToPage`, {
          host: socialApi,
          headers: this.ajax.headers,
          data: {
            routeName,
            moduleName
          }
        });
        if (routeName === 'feed.index.index') {
          Ember.set(this, 'accessedIntro', Boolean(alreadyAccessed));
        }
        if (alreadyAccessed) {
          return;
        }
        this.startNow(permissions);
      } catch (e) {
        Ember.debug(`NelaIntro :: Service Intro.js :: Não foi possível ler as configurações IntroJs para esta página: ${e}`);
      }
    }),
    // Retornar as configurações da página na pasta app/intro/*
    _getPageOptions(type) {
      let routeName = this.router.currentRoute.name.replace(/\./g, '/');
      if (type) {
        routeName = type;
      }
      let ENV = Ember.getOwner(this).factoryFor('config:environment').class;
      let generalOptions = ENV['nela-intro'] || {};
      let specificOptions = (0, _emberRequireModule.default)(`${ENV.APP.name}/intro/${routeName}`) || {};
      let pageOptions = Object.assign(generalOptions, specificOptions);
      if (!pageOptions.steps) {
        throw `IntroJS não foi configurado para a rota ${routeName}`;
      }
      return pageOptions;
    },
    // Habilita/Desabilita os links para não serem clicados durante o tour.
    _disableClicks(steps) {
      steps.forEach(step => {
        let element = document.querySelector(step.element);
        if (!element) {
          return;
        }
        element.addEventListener('click', this.disableLinksFn, true);
      });
    },
    _enableClicks(steps) {
      steps.forEach(step => {
        let element = document.querySelector(step.element);
        if (!element) {
          return;
        }
        element.removeEventListener('click', this.disableLinksFn, true);
      });
    },
    // Desabilita as etapas de acordo com sua configuração.
    _disableSteps(steps, permissions) {
      return steps.filter(step => {
        return !step.disable || !step.disable(permissions);
      });
    },
    // Equivalente ao $().ready
    _docReady(cb) {
      if (document.readyState === 'complete' || document.readyState === 'interactive') {
        setTimeout(cb, 1);
      } else {
        document.addEventListener('DOMContentLoaded', cb);
      }
    }
  });
  _exports.default = _default;
});