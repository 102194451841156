define("ui-nela/templates/components/ui-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XCNXh5Y4",
    "block": "{\"symbols\":[\"@onSubmit\",\"@validations\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[14,3,[[28,\"hash\",null,[[\"label\",\"group\",\"input\",\"checkbox\",\"radio\",\"submit\"],[[28,\"component\",[\"ui-form/label\"],null],[28,\"component\",[\"ui-form/group\"],[[\"validations\"],[[23,2,[]]]]],[28,\"component\",[\"ui-form/input\"],null],[28,\"component\",[\"ui-form/checkbox\"],null],[28,\"component\",[\"ui-form/radio-group\"],null],[28,\"component\",[\"ui-button\"],[[\"click\"],[[23,1,[]]]]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-form.hbs"
    }
  });
  _exports.default = _default;
});