define("ui-nela/templates/components/ui-navbar/nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "PIYG+KP1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"button\",true],[10,\"class\",\"navbar-toggler\"],[10,\"data-toggle\",\"collapse\"],[10,\"data-target\",\"#uiNavbar\"],[10,\"aria-controls\",\"uiNavbar\"],[10,\"aria-expanded\",\"false\"],[10,\"type\",\"button\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"navbar-toggler-icon\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui-navbar__collapse collapse navbar-collapse flex-row-reverse\"],[10,\"id\",\"uiNavbar\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"ui-navbar__nav navbar-nav align-items-center\"],[8],[0,\"\\n    \"],[14,1,[[28,\"hash\",null,[[\"item\",\"modules\",\"user\"],[[28,\"component\",[\"ui-navbar/nav/item\"],null],[28,\"component\",[\"ui-navbar/nav/modules\"],null],[28,\"component\",[\"ui-navbar/nav/user\"],null]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[14,1,[[28,\"hash\",null,[[\"search\"],[[28,\"component\",[\"ui-navbar/nav/search\"],null]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-navbar/nav.hbs"
    }
  });
  _exports.default = _default;
});