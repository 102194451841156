define("ui-nela/templates/components/ui-form/group/label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "63lMH71T",
    "block": "{\"symbols\":[\"@title\"],\"statements\":[[1,[23,1,[]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-form/group/label.hbs"
    }
  });
  _exports.default = _default;
});