define("ui-nela/templates/components/ui-skeleton/text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TNIT3in6",
    "block": "{\"symbols\":[\"@size\",\"&default\"],\"statements\":[[4,\"each\",[[23,0,[\"counter\"]]],null,{\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2,[[28,\"hash\",null,[[\"Line\"],[[28,\"component\",[\"ui-skeleton/text/line\"],[[\"size\"],[[23,1,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"ui-skeleton/text/line\",[],[[\"@size\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-skeleton/text.hbs"
    }
  });
  _exports.default = _default;
});