define("ui-nela/templates/components/ui-navbar/nav/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QLDWfj3D",
    "block": "{\"symbols\":[\"@user\",\"&default\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"ui-navbar__nav__dropdown\"],[8],[0,\"\\n  \"],[14,2,[[28,\"hash\",null,[[\"item\",\"status\"],[[28,\"component\",[\"ui-navbar/nav/dropdown/item\"],null],[28,\"component\",[\"ui-navbar/nav/user/status\"],[[\"user\"],[[23,1,[]]]]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-navbar/nav/dropdown.hbs"
    }
  });
  _exports.default = _default;
});