define("ui-nela/templates/components/ui-page/header/breadcrumbs/crumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5lqILAGl",
    "block": "{\"symbols\":[\"@link\",\"@text\",\"@models\",\"@param\"],\"statements\":[[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"  \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[[23,1,[]],[23,4,[]]]],{\"statements\":[[0,\"\\n    \"],[1,[23,2,[]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"  \"],[5,\"link-to\",[],[[\"@route\",\"@models\"],[[23,1,[]],[23,3,[]]]],{\"statements\":[[0,\"\\n    \"],[1,[23,2,[]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[5,\"link-to\",[],[[\"@route\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n    \"],[1,[23,2,[]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-page/header/breadcrumbs/crumb.hbs"
    }
  });
  _exports.default = _default;
});