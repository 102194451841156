define("ember-maybe-in-element/components/maybe-in-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @renderInPlace}}{{yield}}{{else}}{{#in-element @destinationElement insertBefore=null}}{{yield}}{{/in-element}}{{/if}}
  */
  {
    "id": "+kjNXgoY",
    "block": "{\"symbols\":[\"&default\",\"@destinationElement\",\"@renderInPlace\"],\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[14,1]],\"parameters\":[]},{\"statements\":[[4,\"in-element\",[[23,2,[]]],[[\"guid\",\"guid\",\"nextSibling\"],[\"%cursor:0%\",\"%cursor:0%\",null]],{\"statements\":[[14,1]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-maybe-in-element/components/maybe-in-element.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});