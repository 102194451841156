define("nela-intro/components/btn-intro", ["exports", "nela-intro/templates/components/btn-intro"], function (_exports, _btnIntro) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _btnIntro.default,
    tagName: 'button',
    classNames: ['btn', 'btn-link', 'position-relative'],
    intro: Ember.inject.service(),
    click() {
      let permission = Ember.get(this, 'permission');
      Ember.get(this, 'intro').startNow(permission);
    }
  });
  _exports.default = _default;
});