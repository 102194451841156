define("ui-nela/templates/components/ui-navbar/nav/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GMt+p7yq",
    "block": "{\"symbols\":[\"@user\",\"&default\"],\"statements\":[[7,\"button\",true],[10,\"class\",\"btn text-white border-0 p-0\"],[10,\"data-toggle\",\"dropdown\"],[10,\"aria-haspopup\",\"true\"],[10,\"aria-expanded\",\"false\"],[8],[0,\"\\n  \"],[5,\"ui-avatar\",[[12,\"class\",\"avatar-4\"],[12,\"alt\",[23,1,[\"fullName\"]]]],[[\"@src\"],[[23,1,[\"avatar\"]]]]],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ui-navbar__nav__user__name\"],[8],[0,\"\\n    \"],[1,[23,1,[\"firstName\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,2,[[28,\"hash\",null,[[\"dropdown\"],[[28,\"component\",[\"ui-navbar/nav/dropdown\"],[[\"class\"],[\"dropdown-menu-right\"]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-navbar/nav/user.hbs"
    }
  });
  _exports.default = _default;
});