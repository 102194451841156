define("ui-nela/templates/components/ui-form/radio-group/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ihH4tJiL",
    "block": "{\"symbols\":[\"@name\",\"@value\",\"@text\",\"&default\"],\"statements\":[[5,\"input\",[],[[\"@type\",\"@name\",\"@value\",\"@click\"],[\"radio\",[23,1,[]],[23,2,[]],[28,\"action\",[[23,0,[]],\"selectRadio\",[23,2,[]]],null]]]],[0,\"\\n\\n\"],[7,\"span\",true],[10,\"class\",\"circle mt-1\"],[8],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"check mt-1\"],[8],[9],[0,\"\\n\\n\"],[1,[28,\"if\",[[25,4],[24,[\"yield\"]],[23,3,[]]],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-form/radio-group/radio-button.hbs"
    }
  });
  _exports.default = _default;
});