define("ember-power-select/components/power-select/placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @placeholder}}
    <span class="ember-power-select-placeholder" ...attributes>{{@placeholder}}</span>
  {{/if}}
  
  */
  {
    "id": "ENaHI3mw",
    "block": "{\"symbols\":[\"&attrs\",\"@placeholder\"],\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",false],[12,\"class\",\"ember-power-select-placeholder\"],[13,1],[8],[1,[23,2,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/components/power-select/placeholder.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});