define("ui-nela/templates/components/ui-sidebar/menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gVgfTYuP",
    "block": "{\"symbols\":[\"@link\",\"@icon\",\"@text\",\"@linkTo\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"show\"]]],null,{\"statements\":[[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"    \"],[5,\"link-to\",[[12,\"class\",\"ui-sidebar__sidebar__menu__item__link\"]],[[\"@route\"],[[23,4,[]]]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[11,\"class\",[29,[\"ui-sidebar__sidebar__menu__item__icon fa \",[23,2,[]]]]],[11,\"title\",[23,3,[]]],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"ui-sidebar__sidebar__menu__item__title text-truncate\"],[8],[1,[23,3,[]],false],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"a\",true],[11,\"href\",[23,1,[]]],[10,\"class\",\"ui-sidebar__sidebar__menu__item__link\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[8],[0,\"\\n      \"],[7,\"i\",true],[11,\"class\",[29,[\"ui-sidebar__sidebar__menu__item__icon fa \",[23,2,[]]]]],[11,\"title\",[23,3,[]]],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"ui-sidebar__sidebar__menu__item__title text-truncate\"],[8],[1,[23,3,[]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[14,5,[[28,\"hash\",null,[[\"submenu\"],[[28,\"component\",[\"ui-sidebar/menu-item/submenu\"],[[\"didRender\"],[[28,\"action\",[[23,0,[]],\"onUseSubmenu\"],null]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-sidebar/menu-item.hbs"
    }
  });
  _exports.default = _default;
});