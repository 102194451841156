define("ui-nela/templates/components/ui-form-renderer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4tHFIYri",
    "block": "{\"symbols\":[\"@formName\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"content\",\"submit\",\"cancel\"],[[28,\"component\",[[23,1,[]]],[[\"onLoadForm\"],[[28,\"action\",[[23,0,[]],\"onLoadForm\"],null]]]],[28,\"component\",[\"ui-button\"],[[\"text\",\"icon\",\"click\"],[\"Salvar\",\"fa-save\",[28,\"perform\",[[23,0,[\"submitTask\"]]],null]]]],[28,\"component\",[\"ui-button-link\"],[[\"tagName\",\"text\",\"role\",\"class\"],[\"button\",\"Cancelar\",\"cancel\",\"mr-3\"]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-form-renderer.hbs"
    }
  });
  _exports.default = _default;
});