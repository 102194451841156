define("ui-nela/templates/components/ui-navbar/logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EPP4VGUV",
    "block": "{\"symbols\":[\"@route\"],\"statements\":[[5,\"link-to\",[[12,\"class\",\"ui-navbar__logo\"]],[[\"@route\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n  \"],[7,\"img\",true],[10,\"src\",\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/voors%2Fimages%2Flogo-voors-black.svg\"],[10,\"alt\",\"Voors\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-navbar/logo.hbs"
    }
  });
  _exports.default = _default;
});