define("ember-power-select/components/power-select/trigger", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @select.selected}}
    {{#if @selectedItemComponent}}
      {{component @selectedItemComponent extra=(readonly @extra) option=(readonly @select.selected) select=(readonly @select)}}
    {{else}}
      <span class="ember-power-select-selected-item">{{yield @select.selected @select}}</span>
    {{/if}}
    {{#if (and @allowClear (not @select.disabled))}}
      <span class="ember-power-select-clear-btn" role="button" {{on "mousedown" this.clear}} {{on "touchstart" this.clear}}>&times;</span>
    {{/if}}
  {{else}}
    {{component @placeholderComponent placeholder=@placeholder}}
  {{/if}}
  <span class="ember-power-select-status-icon"></span>
  
  */
  {
    "id": "TUvQo2mX",
    "block": "{\"symbols\":[\"@placeholder\",\"@placeholderComponent\",\"@select\",\"&default\",\"@extra\",\"@selectedItemComponent\",\"@allowClear\"],\"statements\":[[4,\"if\",[[23,3,[\"selected\"]]],null,{\"statements\":[[4,\"if\",[[23,6,[]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[23,6,[]]],[[\"extra\",\"option\",\"select\"],[[28,\"readonly\",[[23,5,[]]],null],[28,\"readonly\",[[23,3,[\"selected\"]]],null],[28,\"readonly\",[[23,3,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"ember-power-select-selected-item\"],[8],[14,4,[[23,3,[\"selected\"]],[23,3,[]]]],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[28,\"and\",[[23,7,[]],[28,\"not\",[[23,3,[\"disabled\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",false],[12,\"class\",\"ember-power-select-clear-btn\"],[12,\"role\",\"button\"],[3,\"on\",[\"mousedown\",[23,0,[\"clear\"]]]],[3,\"on\",[\"touchstart\",[23,0,[\"clear\"]]]],[8],[0,\"×\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[23,2,[]]],[[\"placeholder\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[7,\"span\",true],[10,\"class\",\"ember-power-select-status-icon\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/components/power-select/trigger.hbs"
    }
  });
  let Trigger = (_dec = Ember._action, (_class = class Trigger extends _component.default {
    clear(e) {
      e.stopPropagation();
      this.args.select.actions.select(null);
      if (e.type === 'touchstart') {
        return false;
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "clear", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype)), _class));
  _exports.default = Trigger;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Trigger);
});