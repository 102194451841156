define("ui-nela/templates/components/ui-sidebar/menu-item/submenu/submenu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bb6cb0dM",
    "block": "{\"symbols\":[\"@link\",\"@text\",\"@linkTo\"],\"statements\":[[4,\"if\",[[23,0,[\"show\"]]],null,{\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"    \"],[5,\"link-to\",[[12,\"class\",\"text-truncate\"],[12,\"title\",[23,2,[]]]],[[\"@route\"],[[23,3,[]]]],{\"statements\":[[0,\"\\n      \"],[1,[23,2,[]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"a\",true],[11,\"href\",[23,1,[]]],[10,\"class\",\"text-truncate\"],[11,\"title\",[23,2,[]]],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[8],[0,\"\\n      \"],[1,[23,2,[]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-sidebar/menu-item/submenu/submenu-item.hbs"
    }
  });
  _exports.default = _default;
});