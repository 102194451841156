define("ui-nela/templates/components/ui-form/group/status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mDRzVn4C",
    "block": "{\"symbols\":[\"@message\"],\"statements\":[[1,[28,\"if\",[[23,1,[]],[28,\"concat\",[\"* \",[23,1,[]]],null]],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-form/group/status.hbs"
    }
  });
  _exports.default = _default;
});