define("ui-nela/templates/components/ui-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "80FW8PvO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"aside\",true],[10,\"class\",\"ui-sidebar__sidebar\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"ui-sidebar__sidebar__menu\"],[8],[0,\"\\n    \"],[7,\"li\",true],[10,\"class\",\"ui-sidebar__sidebar__menu__item ui-sidebar__sidebar__menu__item--open\"],[8],[0,\"\\n      \"],[7,\"span\",false],[12,\"class\",\"ui-sidebar__sidebar__menu__item__link\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"toggleMenu\"]],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"ui-sidebar__sidebar__menu__item__icon fa fa-bars\"],[10,\"title\",\"Expandir menu\"],[8],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"ui-sidebar__sidebar__menu__item__title\"],[8],[0,\"Recolher menu\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[14,1,[[28,\"hash\",null,[[\"item\"],[[28,\"component\",[\"ui-sidebar/menu-item\"],null]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-sidebar.hbs"
    }
  });
  _exports.default = _default;
});