define("ember-power-select/templates/components/power-select/power-select-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nAfT5E2F",
    "block": "{\"symbols\":[\"@group\",\"&default\"],\"statements\":[[7,\"li\",true],[10,\"class\",\"ember-power-select-group\"],[11,\"aria-disabled\",[28,\"if\",[[23,1,[\"disabled\"]],\"true\"],null]],[10,\"role\",\"option\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"ember-power-select-group-name\"],[8],[1,[23,1,[\"groupName\"]],false],[9],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/power-select-group.hbs"
    }
  });
  _exports.default = _default;
});