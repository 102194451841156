define("nela-select/components/nela-select", ["exports", "nela-select/components/nela-select-basic", "nela-select/templates/components/nela-select"], function (_exports, _nelaSelectBasic, _nelaSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nelaSelectBasic.default.extend({
    layout: _nelaSelect.default,
    classNames: ['nela-select'],
    actions: {
      handleChange(selected) {
        if (this.onChange) {
          this.onChange(...arguments);
        } else {
          Ember.set(this, 'selected', selected);
          if (this.afterChange) {
            this.afterChange(...arguments);
          }
        }
      }
    }
  });
  _exports.default = _default;
});