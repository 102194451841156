define("ui-nela/templates/components/ui-navbar/nav/modules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SM/IMNF6",
    "block": "{\"symbols\":[\"module\"],\"statements\":[[7,\"button\",true],[10,\"class\",\"ui-navbar__nav__btn\"],[10,\"data-toggle\",\"dropdown\"],[10,\"role\",\"button\"],[10,\"aria-haspopup\",\"true\"],[10,\"aria-expanded\",\"false\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-th\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui-navbar__nav__modules dropdown-menu centralize-x\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row align-items-center\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"modules\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"col-sm-4 text-center\"],[8],[0,\"\\n          \"],[7,\"a\",true],[11,\"href\",[28,\"app-url\",[[23,1,[\"type\"]]],null]],[10,\"class\",\"ui-navbar__nav__modules__icon\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[8],[0,\"\\n            \"],[7,\"img\",true],[11,\"src\",[29,[\"https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grfjqezlbdq4/b/nela-cdn/o/icons/\",[23,1,[\"type\"]],\".svg\"]]],[11,\"alt\",[23,1,[\"title\"]]],[8],[9],[0,\"\\n            \"],[7,\"p\",true],[10,\"class\",\"mb-0\"],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-navbar/nav/modules.hbs"
    }
  });
  _exports.default = _default;
});