define("nela-dataloader/templates/components/nela-paging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WMUYQYPe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"loadMore\",\"records\",\"error\",\"loading\",\"Empty\",\"Loading\",\"LoadMore\"],[[28,\"action\",[[23,0,[]],\"loadMore\"],null],[23,0,[\"records\"]],[23,0,[\"loadRecords\",\"last\",\"error\"]],[23,0,[\"loadRecords\",\"isRunning\"]],[28,\"component\",[\"nela-paging/empty\"],[[\"hideEmpty\"],[[24,[\"hideEmpty\"]]]]],[28,\"component\",[\"nela-paging/loading\"],[[\"hideLoading\"],[[24,[\"hideLoading\"]]]]],[28,\"component\",[\"nela-paging/btn-load-more\"],[[\"hideLoadMore\",\"click\"],[[24,[\"hideLoadMore\"]],[28,\"action\",[[23,0,[]],\"loadMore\"],null]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nela-dataloader/templates/components/nela-paging.hbs"
    }
  });
  _exports.default = _default;
});