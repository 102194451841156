define("ui-nela/templates/components/ui-modal/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tW8Ihvoq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"h5\",true],[10,\"class\",\"modal-title\"],[8],[14,1],[9],[0,\"\\n\\n\"],[7,\"button\",true],[10,\"class\",\"close\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"closeModal\"],null]],[10,\"type\",\"button\"],[8],[0,\"\\n  \"],[7,\"i\",true],[10,\"class\",\"fa fa-times\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-modal/title.hbs"
    }
  });
  _exports.default = _default;
});