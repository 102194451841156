define("ui-nela/templates/components/ui-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "rF+jHPP2",
    "block": "{\"symbols\":[\"@checkboxId\",\"@value\",\"@checked\",\"@autofocus\",\"@disabled\",\"@indeterminate\",\"@name\",\"@required\",\"@tabindex\",\"@aria-labelledby\",\"@aria-describedby\",\"&default\"],\"statements\":[[5,\"ui-checkbox/input\",[[12,\"id\",[28,\"or\",[[23,1,[]],[28,\"concat\",[[23,0,[\"elementId\"]],\"-input\"],null]],null]],[12,\"autofocus\",[23,4,[]]],[12,\"disabled\",[23,5,[]]],[12,\"indeterminate\",[23,6,[]]],[12,\"name\",[23,7,[]]],[12,\"required\",[23,8,[]]],[12,\"tabindex\",[23,9,[]]],[12,\"aria-labelledby\",[23,10,[]]],[12,\"aria-describedby\",[23,11,[]]],[12,\"type\",\"checkbox\"]],[[\"@value\",\"@checked\",\"@onChange\"],[[23,2,[]],[23,3,[]],[28,\"action\",[[23,0,[]],\"handleChange\"],null]]]],[0,\"\\n\\n\"],[7,\"label\",true],[11,\"for\",[28,\"or\",[[23,1,[]],[28,\"concat\",[[23,0,[\"elementId\"]],\"-input\"],null]],null]],[8],[14,12],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-checkbox.hbs"
    }
  });
  _exports.default = _default;
});