define("ui-nela/templates/components/ui-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aI1DMUFs",
    "block": "{\"symbols\":[\"@radioId\",\"@option\",\"@selected\",\"@name\",\"@autofocus\",\"@disabled\",\"@required\",\"@tabindex\",\"@aria-labelledby\",\"@aria-describedby\",\"&default\"],\"statements\":[[7,\"label\",true],[10,\"class\",\"ui-radio__label\"],[11,\"for\",[28,\"or\",[[23,1,[]],[28,\"concat\",[\"ui-radio-\",[23,0,[\"elementId\"]]],null]],null]],[8],[0,\"\\n  \"],[5,\"ui-radio/input\",[[12,\"id\",[28,\"or\",[[23,1,[]],[28,\"concat\",[\"ui-radio-\",[23,0,[\"elementId\"]]],null]],null]],[12,\"tabindex\",[23,8,[]]],[12,\"aria-labelledby\",[23,9,[]]],[12,\"aria-describedby\",[23,10,[]]]],[[\"@option\",\"@selected\",\"@onChange\",\"@name\",\"@autofocus\",\"@disabled\",\"@required\"],[[23,2,[]],[23,3,[]],[28,\"action\",[[23,0,[]],\"onChange\"],null],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]]]]],[0,\"\\n\\n  \"],[7,\"span\",true],[10,\"class\",\"ui-radio__circle\"],[8],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"ui-radio__check\"],[8],[9],[0,\"\\n  \"],[14,11],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-radio.hbs"
    }
  });
  _exports.default = _default;
});