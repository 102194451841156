define("ember-composable-helpers/helpers/contains", ["exports", "ember-composable-helpers/helpers/includes"], function (_exports, _includes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contains = contains;
  _exports.default = void 0;
  function contains(needleOrNeedles, haystack) {
    (true && !(false) && Ember.deprecate('{{contains}} helper provided by ember-composable-helpers has been renamed to {{includes}}.', false, {
      id: 'ember-composable-helpers.contains-helper',
      until: '5.0.0'
    }));
    return (0, _includes.includes)(needleOrNeedles, haystack);
  }
  var _default = Ember.Helper.helper(function (_ref) {
    let [needle, haystack] = _ref;
    return contains(needle, haystack);
  });
  _exports.default = _default;
});