define("ui-nela/templates/components/ui-form/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "37KMjv1B",
    "block": "{\"symbols\":[\"@form\",\"@value\",\"@disabled\",\"@indeterminate\",\"@name\",\"@tabindex\",\"@autofocus\",\"@text\",\"&default\"],\"statements\":[[7,\"label\",true],[8],[0,\"\\n  \"],[5,\"input\",[[12,\"autofocus\",[23,7,[]]]],[[\"@type\",\"@form\",\"@checked\",\"@disabled\",\"@indeterminate\",\"@change\",\"@name\",\"@tabindex\"],[\"checkbox\",[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]],[28,\"action\",[[23,0,[]],\"onChange\"],null],[23,5,[]],[23,6,[]]]]],[0,\"\\n\\n  \"],[7,\"span\",true],[10,\"class\",\"checkbox-material\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"check\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[28,\"if\",[[25,9],[24,[\"yield\"]],[23,8,[]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-form/checkbox.hbs"
    }
  });
  _exports.default = _default;
});