define("ember-power-select/templates/components/power-select/placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vROXXGo9",
    "block": "{\"symbols\":[\"&attrs\",\"@placeholder\"],\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",false],[12,\"class\",\"ember-power-select-placeholder\"],[13,1],[8],[1,[23,2,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/placeholder.hbs"
    }
  });
  _exports.default = _default;
});