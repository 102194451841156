define("ember-power-calendar/templates/components/power-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mLJdVm7W",
    "block": "{\"symbols\":[\"calendar\",\"Tag\",\"&default\",\"&attrs\"],\"statements\":[[4,\"with\",[[28,\"assign\",[[23,0,[\"publicAPI\"]],[28,\"hash\",null,[[\"Nav\",\"Days\"],[[28,\"component\",[[23,0,[\"navComponent\"]]],[[\"calendar\"],[[28,\"readonly\",[[23,0,[\"publicAPI\"]]],null]]]],[28,\"component\",[[23,0,[\"daysComponent\"]]],[[\"calendar\"],[[28,\"readonly\",[[23,0,[\"publicAPI\"]]],null]]]]]]]],null]],null,{\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"-element\",[[23,0,[\"tagWithDefault\"]]],null]],[[\"tagName\"],[[23,0,[\"tagWithDefault\"]]]]]],null,{\"statements\":[[0,\"    \"],[6,[23,2,[]],[[12,\"class\",\"ember-power-calendar\"],[13,4],[12,\"id\",[23,1,[\"uniqueId\"]]]],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"        \"],[14,3,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,[23,1,[\"Nav\"]],[],[[],[]]],[0,\"\\n        \"],[6,[23,1,[\"Days\"]],[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-calendar/templates/components/power-calendar.hbs"
    }
  });
  _exports.default = _default;
});