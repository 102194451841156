define("nela-dataloader/templates/components/nela-paging/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5J1RI5A4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nela-dataloader/templates/components/nela-paging/loading.hbs"
    }
  });
  _exports.default = _default;
});