define("nela-select/components/nela-select-basic", ["exports", "ember-concurrency", "ember-inflector"], function (_exports, _emberConcurrency, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    // Power Select Attributes
    renderInPlace: true,
    searchEnabled: true,
    placeholder: 'Selecione',
    loadingMessage: 'Buscando registros...',
    searchMessage: 'Digite para pesquisar',
    noMatchesMessage: 'Nenhum registro encontrado',
    // Concurrency Tasks
    findRecords: (0, _emberConcurrency.task)(function* (term) {
      // Caso o método esteja sendo utilizado pela pesquisa, adiciona um timeout.
      if (term) {
        yield (0, _emberConcurrency.timeout)(300);
      }
      let query = this.query || {};
      let endpoint = this.endpoint || this.endPoint;
      let modelName = this.modelName;

      // Cria um endpoint baseado no modelName informado.
      if (!endpoint && modelName) {
        endpoint = `${(0, _emberInflector.pluralize)(Ember.String.camelize(modelName))}/ajax`;
      }
      Ember.assign(query, {
        term
      });
      try {
        let records;
        if (this.modelName) {
          records = yield this.store.loadRecords(modelName, endpoint, query, this.host);
        } else {
          let options = {};
          options.data = query;

          // Faz o select buscar na api de outro módulo
          const environment = Ember.getOwner(this).factoryFor('config:environment').class;
          if (this.host && environment) {
            options.host = environment.apiUrl[this.host];
          }
          records = yield this.ajax.request(endpoint, options);
        }
        if (this.selectProperty && !this.modelName) {
          records = records.map(o => o[this.selectProperty]);
        }
        Ember.set(this, 'options', records);
      } catch (e) {
        Ember.debug(e);
      }
    }).restartable(),
    actions: {
      handleOpen() {
        if (this.onOpen) {
          this.onOpen();
        } else {
          this.findRecords.perform();
        }
      },
      handleSearch(term) {
        if (this.search) {
          this.search();
        } else {
          this.findRecords.perform(term);
        }
      }
    }
  });
  _exports.default = _default;
});