define("ui-nela/templates/components/ui-button-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IvV7+lJ/",
    "block": "{\"symbols\":[\"@link\",\"@target\",\"@btnIcon\",\"@text\",\"@linkTo\",\"@params\"],\"statements\":[[4,\"if\",[[23,5,[]]],null,{\"statements\":[[4,\"if\",[[23,6,[]]],null,{\"statements\":[[4,\"link-to\",null,[[\"route\",\"model\"],[[23,5,[]],[23,6,[]]]],{\"statements\":[[0,\"      \"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[23,3,[]]]]],[8],[9],[0,\" \"],[1,[23,4,[]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"link-to\",[],[[\"@route\"],[[23,5,[]]]],{\"statements\":[[0,\"\\n      \"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[23,3,[]]]]],[8],[9],[0,\" \"],[1,[23,4,[]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"a\",true],[11,\"href\",[23,1,[]]],[11,\"target\",[23,2,[]]],[8],[0,\"\\n    \"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[23,3,[]]]]],[8],[9],[0,\" \"],[1,[23,4,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-button-link.hbs"
    }
  });
  _exports.default = _default;
});