define("ui-nela/templates/components/ui-skeleton", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "q1RlUZAz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"Render\",\"Text\",\"Avatar\"],[[28,\"component\",[\"ui-skeleton/render\"],null],[28,\"component\",[\"ui-skeleton/text\"],null],[28,\"component\",[\"ui-skeleton/avatar\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-skeleton.hbs"
    }
  });
  _exports.default = _default;
});