define("ui-nela/templates/components/ui-page/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RbBDvUT9",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n    \"],[14,1,[[28,\"hash\",null,[[\"title\",\"breadcrumbs\"],[[28,\"component\",[\"ui-page/header/title\"],null],[28,\"component\",[\"ui-page/header/breadcrumbs\"],null]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-page/header.hbs"
    }
  });
  _exports.default = _default;
});