define("ui-nela/templates/components/ui-navbar/brand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "alSDcRhD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-navbar/brand.hbs"
    }
  });
  _exports.default = _default;
});