define("nela-dataloader/services/store", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Store.extend({
    ajax: Ember.inject.service(),
    loadRecord(modelName, endpoint, query, host) {
      const environment = Ember.getOwner(this).factoryFor('config:environment').class;
      let options = {
        data: query
      };
      if (host && environment) {
        options.host = environment.apiUrl[host];
      }
      return this.ajax.request(endpoint, options).then(response => {
        let camelizeKey = Ember.String.camelize(modelName);
        let dasherizeKey = Ember.String.dasherize(modelName);
        let meta = response.meta;
        if (meta) {
          delete response.meta;
        }
        this.pushPayload(response);
        let responseRecord = response[camelizeKey] || response[dasherizeKey];
        if (!responseRecord) {
          throw 'Fomato dos dados de retorno é inválido. Root do JSON não encontrado';
        }
        let normalized = this.peekRecord(modelName, responseRecord.id);
        if (meta) {
          Ember.set(normalized, 'meta', meta);
        }
        return normalized;
      }).catch(e => {
        Ember.debug(`Nela Dataloader :: loadRecord :: ${e}`);
        throw e;
      });
    },
    loadRecords(modelName, endpoint, query, host) {
      const environment = Ember.getOwner(this).factoryFor('config:environment').class;
      let options = {
        data: query
      };
      if (host && environment) {
        options.host = environment.apiUrl[host];
      }
      return this.ajax.request(endpoint, options).then(response => {
        let camelizeKey = Ember.String.camelize((0, _emberInflector.pluralize)(modelName));
        let dasherizeKey = Ember.String.dasherize((0, _emberInflector.pluralize)(modelName));
        if (Ember.isArray(response)) {
          response = {
            [`${camelizeKey}`]: response
          };
        }
        let responseArray = response[camelizeKey] || response[dasherizeKey] || response[modelName];
        if (!responseArray) {
          throw 'Formato dos dados de retorno é inválido. Root do JSON não encontrado';
        }
        let meta = response.meta;
        if (meta) {
          delete response.meta;
        }
        this.pushPayload(response);
        let normalizedResponse = responseArray.map(obj => {
          return this.peekRecord(modelName, obj.id);
        });
        if (meta) {
          Ember.set(normalizedResponse, 'meta', meta);
        }
        return normalizedResponse;
      }).catch(e => {
        Ember.debug(`Nela Dataloader :: loadRecords :: ${e}`);
        throw e;
      });
    }
  });
  _exports.default = _default;
});