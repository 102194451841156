define("ember-power-select/templates/components/power-select/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "C7a9cDhu",
    "block": "{\"symbols\":[\"@placeholder\",\"@placeholderComponent\",\"@select\",\"&default\",\"@extra\",\"@selectedItemComponent\",\"@allowClear\"],\"statements\":[[4,\"if\",[[23,3,[\"selected\"]]],null,{\"statements\":[[4,\"if\",[[23,6,[]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[23,6,[]]],[[\"extra\",\"option\",\"select\"],[[28,\"readonly\",[[23,5,[]]],null],[28,\"readonly\",[[23,3,[\"selected\"]]],null],[28,\"readonly\",[[23,3,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"ember-power-select-selected-item\"],[8],[14,4,[[23,3,[\"selected\"]],[24,[\"select\"]]]],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[28,\"and\",[[23,7,[]],[28,\"not\",[[23,3,[\"disabled\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",false],[12,\"class\",\"ember-power-select-clear-btn\"],[3,\"on\",[\"mousedown\",[23,0,[\"clear\"]]]],[3,\"on\",[\"touchstart\",[23,0,[\"clear\"]]]],[8],[0,\"×\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[23,2,[]]],[[\"placeholder\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[7,\"span\",true],[10,\"class\",\"ember-power-select-status-icon\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/trigger.hbs"
    }
  });
  _exports.default = _default;
});