define("ui-nela/templates/components/ui-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ehJJrq7/",
    "block": "{\"symbols\":[\"@modalSize\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"modal-dialog \",[23,1,[]]]]],[10,\"role\",\"document\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n    \"],[14,2,[[28,\"hash\",null,[[\"title\",\"body\",\"footer\"],[[28,\"component\",[\"ui-modal/title\"],[[\"closeModal\"],[[28,\"action\",[[23,0,[]],\"closeModal\"],null]]]],[28,\"component\",[\"ui-modal/body\"],null],[28,\"component\",[\"ui-modal/footer\"],[[\"closeModal\"],[[28,\"action\",[[23,0,[]],\"closeModal\"],null]]]]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-modal.hbs"
    }
  });
  _exports.default = _default;
});