define("nela-intro/templates/components/btn-intro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FV95gErF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\",true],[10,\"class\",\"fa fa-info-circle\"],[10,\"title\",\"Ajuda\"],[10,\"aria-hidden\",\"\"],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"intro\",\"showAlertButton\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"spinner-grow spinner-grow-lg position-absolute t-0 l-1 text-primary\"],[10,\"role\",\"status\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nela-intro/templates/components/btn-intro.hbs"
    }
  });
  _exports.default = _default;
});