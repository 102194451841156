define("nela-dataloader/components/nela-paging", ["exports", "nela-dataloader/templates/components/nela-paging", "ember-concurrency"], function (_exports, _nelaPaging, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    layout: _nelaPaging.default,
    tagName: '',
    // Services
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    // Computed
    hideEmpty: Ember.computed.or('loadRecords.isRunning', 'records.length'),
    hideLoading: Ember.computed.or('loadRecords.isIdle'),
    hideLoadMore: Ember.computed.or('loadRecords.isRunning', 'noMoreData'),
    // Life Cycle
    init() {
      this._super(...arguments);
      this.skip = this.skip || 0;
      this.limit = this.limit || 10;
      this.records = this.records || [];
      this.sort = this.sort || 'createdAt DESC';
    },
    didInsertElement() {
      this._super(...arguments);
      this.loadRecords.perform();
    },
    didUpdateAttrs() {
      this._super(...arguments);

      /* Quando os filtros forem atualizados, reseta
      o SKIP para voltar a buscar do início. */
      Ember.set(this, 'skip', 0);
      Ember.set(this, 'records', []);
      this.loadRecords.perform();
    },
    // Tasks
    loadRecords: (0, _emberConcurrency.task)(function* () {
      let filters = Ember.assign(this.filters || {}, {
        sort: this.sort,
        skip: this.skip,
        limit: this.limit
      });
      let records = Ember.A();
      try {
        if (this.endpoint && this.modelName) {
          records = yield this.store.loadRecords(this.modelName, this.endpoint, filters);
        } else if (this.modelName) {
          records = yield this.store.query(this.modelName, filters);
        } else if (this.endpoint) {
          records = yield this.ajax.request(this.endpoint, {
            data: filters
          });
        } else {
          Ember.debug('Ops!, para utilizar o NelaPaging é necessário informar um @modelName e/ou @endpoint');
        }
      } catch (e) {
        Ember.debug(`<NelaPaging> :: loadRecords :: ${e}`);
      }

      /* Se SKIP é igual a 0 reseta o array de registros
      ** caso contrário adiciona os itens no array */
      if (this.skip === 0) {
        Ember.set(this, 'records', records.toArray());
      } else {
        this.records.addObjects(records.toArray());
      }

      // Quando os registros acabarem, oculta o botão "ver mais".
      Ember.set(this, 'noMoreData', records.length < this.limit);
    }).restartable(),
    // Actions
    actions: {
      loadMore() {
        this.incrementProperty('skip', this.limit);
        this.loadRecords.perform();
      }
    }
  });
  _exports.default = _default;
});