define("ui-nela/components/ui-login", ["exports", "ember-concurrency", "ui-nela/templates/components/ui-login"], function (_exports, _emberConcurrency, _uiLogin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // HTML Attributes
    layout: _uiLogin.default,
    classNames: ['ui-login'],
    // Services
    session: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Default values
    error: null,
    openCompanyModal: false,
    token: null,
    users: null,
    email: null,
    cellphone: null,
    faEye: 'fa fa-eye',
    // Lifecycle hooks
    init() {
      this._super(...arguments);
      (true && !(this.appName) && Ember.assert('<UiLogin> requires an `@appName`.', this.appName));
    },
    forgotPasswordLink: Ember.computed('icon', function () {
      const from = this.getModule();
      const environment = Ember.getOwner(this).factoryFor('config:environment').class;
      const link = `${environment.appUrl.conta}forgot-password?from=${from}`;
      return link;
    }),
    // Tasks
    authenticate: (0, _emberConcurrency.task)(function* (user) {
      const onAuthenticate = this.onAuthenticate;
      try {
        yield this.session.authenticate('authenticator:jwt', {
          token: this.token,
          user: user.id,
          company: user.companyId
        });
        if (onAuthenticate) {
          onAuthenticate();
        }
      } catch (e) {
        if (e.text) Ember.set(this, 'error', e.text);else Ember.set(this, 'error', e.payload || e);
        this.toast.error(this.error);
      }
    }).drop(),
    login: (0, _emberConcurrency.task)(function* (e) {
      try {
        const ajax = Ember.getOwner(this).lookup('service:ajax');
        let {
          apiUrl
        } = Ember.getOwner(this).factoryFor('config:environment').class || {};
        e.preventDefault();
        yield (0, _emberConcurrency.timeout)(250);
        const module = this.getModule();
        let response = yield ajax.post('/auth/account', {
          host: apiUrl.conta,
          data: {
            identification: this.identification,
            password: this.password,
            module: module
          }
        });
        Ember.set(this, 'token', response.token);
        Ember.set(this, 'userAccount', response.userAccount);
        Ember.set(this, 'email', this.userAccount.email);
        if (response.users && response.users.length === 1) {
          let user = response.users[0];
          yield this.authenticate.perform(user);
          return;
        }
        Ember.set(this, 'users', response.users);
        Ember.set(this, 'openCompanyModal', true);
        return;
      } catch (e) {
        Ember.set(this, 'error', e.payload || e);
        if (this.error === 'The ajax operation was aborted') {
          if (window.navigator.onLine) {
            this.error = 'O servidor encontrou um erro. Tente novamente mais tarde';
          } else {
            this.error = 'Parece que você não esta conectado à internet.';
          }
        }
        this.toast.error(this.error);
      }
    }).drop(),
    // Methods
    getModule() {
      const icon = this.icon;
      if (icon === 'painel_admin') {
        return 'admin';
      } else if (icon === 'avd') {
        return 'gestao_desempenho';
      } else if (icon === 'social') {
        return 'social';
      } else if (icon === 'ted') {
        return 'educacao';
      } else if (icon === 'projetos') {
        return 'projetos';
      } else if (icon === 'pesquisa') {
        return 'pesquisa';
      }
    },
    actions: {
      showPassword() {
        const [_, inputPassword] = document.querySelectorAll('.input-login');
        const inputPasswordType = inputPassword.getAttribute('type');
        if (inputPasswordType === 'password') {
          inputPassword.setAttribute('type', 'text');
          Ember.set(this, 'faEye', 'fa fa-eye-slash');
        } else {
          inputPassword.setAttribute('type', 'password');
          Ember.set(this, 'faEye', 'fa fa-eye');
        }
      }
    }
  });
  _exports.default = _default;
});