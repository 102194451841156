define("ui-nela/templates/components/ui-page/header/breadcrumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YpVH5n1C",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"link\"],[[28,\"component\",[\"ui-page/header/breadcrumbs/crumb\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui-nela/templates/components/ui-page/header/breadcrumbs.hbs"
    }
  });
  _exports.default = _default;
});